import React from 'react';
import Image from 'next/image';
import AppLink from './app-link';

type Props = {
  width?: number;
  height?: number;
  noHref?: boolean | undefined;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  light?: boolean | undefined
};

const Logo = ({ width = 260, height = 60, noHref = false, light = false, onClick }: Props) => {
  return noHref ? (
    <>
      <Image className="cursor-pointer" src={light ? '/images/app/light-logo.png' : '/images/app/logo.webp'} alt="yemekoni" layout="fixed" objectFit='contain' width={width} height={height} />
    </>
  ) : (
    <AppLink onClick={onClick} href="/" className="flex items-center">
      <Image className="cursor-pointer" src={light ? '/images/app/light-logo.png' : '/images/app/logo.webp'} alt="yemekoni" layout="fixed" objectFit='contain' width={width} height={height} />
    </AppLink>
  );
};

export default Logo;
