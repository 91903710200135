import classnames from 'classnames';
import React from 'react';

type Props = {
  className?: string | undefined;
  children: React.ReactNode;
  color?: 'primary' | 'secondary' | undefined
};

const ListItem = ({ className, children, color }: Props) => {
  const classProps = classnames(
    className ? className : '',
    color === 'primary' ? 'text-[#1C6162]' : '',
    color === 'secondary' ? 'text-[#33BC92]' : '',
  )
  return <li className={classProps}>{children}</li>;
};

export default ListItem;
