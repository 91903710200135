import React from 'react';

type Props = {
  width?: string | number | undefined;
  height?: string | number | undefined;
  color?: string | undefined;
};

const useIcon = () => {
  const CloseIcon = ({ width = 20, height = 20, color = 'currentColor' }: Props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-x"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    );
  };

  const HamburgerIcon = ({ width = 20, height = 20, color = 'currentColor' }: Props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-menu"
      >
        <line x1="3" y1="12" x2="21" y2="12"></line>
        <line x1="3" y1="6" x2="21" y2="6"></line>
        <line x1="3" y1="18" x2="21" y2="18"></line>
      </svg>
    );
  };

  const ChevronDownIcon = ({ width = 32, height = 32, color = 'currentColor' }: Props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-chevron-down"
      >
        <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
    );
  };

  const PeoplesIcon = ({ width = 75, height = 53, color = 'white' }: Props) => {
    return (
      <svg width={width} height={height} viewBox="0 0 75 53" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.25 22.75C15.3516 22.75 18.75 19.4688 18.75 15.25C18.75 11.1484 15.3516 7.75 11.25 7.75C7.03125 7.75 3.75 11.1484 3.75 15.25C3.75 19.4688 7.03125 22.75 11.25 22.75ZM63.75 22.75C67.8516 22.75 71.25 19.4688 71.25 15.25C71.25 11.1484 67.8516 7.75 63.75 7.75C59.5312 7.75 56.25 11.1484 56.25 15.25C56.25 19.4688 59.5312 22.75 63.75 22.75ZM67.5 26.5H60C57.8906 26.5 56.0156 27.4375 54.6094 28.7266C59.4141 31.3047 62.6953 35.9922 63.5156 41.5H71.25C73.2422 41.5 75 39.8594 75 37.75V34C75 29.8984 71.6016 26.5 67.5 26.5ZM37.5 26.5C44.6484 26.5 50.625 20.6406 50.625 13.375C50.625 6.22656 44.6484 0.25 37.5 0.25C30.2344 0.25 24.375 6.22656 24.375 13.375C24.375 20.6406 30.2344 26.5 37.5 26.5ZM46.4062 30.25H45.4688C43.0078 31.4219 40.3125 32.125 37.5 32.125C34.5703 32.125 31.875 31.4219 29.4141 30.25H28.4766C20.9766 30.25 15 36.3438 15 43.8438V47.125C15 50.2891 17.4609 52.75 20.625 52.75H54.375C57.4219 52.75 60 50.2891 60 47.125V43.8438C60 36.3438 53.9062 30.25 46.4062 30.25ZM20.2734 28.7266C18.8672 27.4375 16.9922 26.5 15 26.5H7.5C3.28125 26.5 0 29.8984 0 34V37.75C0 39.8594 1.64062 41.5 3.75 41.5H11.3672C12.1875 35.9922 15.4688 31.3047 20.2734 28.7266Z" fill={color} />
      </svg>
    );
  };

  const CoffeeIcon = ({ width = 60, height = 61, color = 'white' }: Props) => {
    return (
      <svg width={width} height={height} viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.8828 17.7266C15 18.6641 15.8203 19.25 16.7578 19.25H18.6328C19.8047 19.25 20.7422 18.3125 20.5078 17.1406C20.1562 13.8594 18.6328 10.8125 16.2891 8.46875C14.5312 6.71094 13.4766 4.48438 13.125 2.14062C13.0078 1.20312 12.3047 0.5 11.25 0.5H9.375C8.20312 0.5 7.38281 1.55469 7.5 2.60938C7.96875 6.35938 9.60938 9.875 12.1875 12.5703C13.5938 13.9766 14.5312 15.7344 14.8828 17.7266ZM28.0078 17.7266C28.125 18.6641 28.9453 19.25 29.8828 19.25H31.7578C32.9297 19.25 33.8672 18.3125 33.6328 17.1406C33.2812 13.8594 31.7578 10.8125 29.4141 8.46875C27.6562 6.71094 26.6016 4.48438 26.25 2.14062C26.1328 1.20312 25.4297 0.5 24.375 0.5H22.5C21.3281 0.5 20.3906 1.55469 20.625 2.60938C21.0938 6.35938 22.7344 9.875 25.3125 12.5703C26.7188 13.9766 27.6562 15.7344 28.0078 17.7266ZM46.875 23H3.75C1.64062 23 0 24.7578 0 26.75V49.25C0 55.4609 5.03906 60.5 11.25 60.5H33.75C39.9609 60.5 45 55.4609 45 49.25H46.875C54.0234 49.25 60 43.3906 60 36.125C60 28.9766 54.0234 23 46.875 23ZM46.875 41.75H45V30.5H46.875C49.9219 30.5 52.5 33.0781 52.5 36.125C52.5 39.2891 49.9219 41.75 46.875 41.75Z" fill={color} />
      </svg>
    );
  };

  const PizzaIcon = ({ width = 61, height = 61, color = 'white' }: Props) => {
    return (
      <svg width={width} height={height} viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5156 0.617188C16.6406 0.382812 14.8828 1.55469 14.4141 3.42969L12.6562 9.99219C34.6875 10.6953 49.5703 25.3438 50.5078 47.1406L57.1875 45.2656C58.9453 44.7969 60.1172 43.0391 59.8828 41.0469C57.4219 19.9531 39.8438 2.60938 18.5156 0.617188ZM11.7188 13.7422L0 58.1562C0 58.2734 0 58.5078 0 58.625C0 59.6797 0.820312 60.5 1.875 60.5C1.99219 60.5 2.22656 60.5 2.34375 60.5L46.7578 48.1953C46.2891 27.6875 32.3438 13.9766 11.7188 13.7422ZM15 49.25C12.8906 49.25 11.25 47.6094 11.25 45.5C11.25 43.5078 12.8906 41.75 15 41.75C16.9922 41.75 18.75 43.5078 18.75 45.5C18.75 47.6094 16.9922 49.25 15 49.25ZM20.625 31.4375C18.5156 31.4375 16.875 29.7969 16.875 27.6875C16.875 25.6953 18.5156 23.9375 20.625 23.9375C22.6172 23.9375 24.375 25.6953 24.375 27.6875C24.375 29.7969 22.6172 31.4375 20.625 31.4375ZM32.8125 43.625C30.7031 43.625 29.0625 41.9844 29.0625 39.875C29.0625 37.8828 30.7031 36.125 32.8125 36.125C34.8047 36.125 36.5625 37.8828 36.5625 39.875C36.5625 41.9844 34.8047 43.625 32.8125 43.625Z" fill={color} />
      </svg>
    );
  };

  const ChevronForward = ({ width = 22, height = 22, color = 'white' }: Props) => {
    return (
      <svg width={width} height={height} viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.3594 11.7969C12.7812 11.375 12.7812 10.6719 12.3594 10.25L3.26562 1.10938C2.79688 0.6875 2.09375 0.6875 1.67188 1.10938L0.59375 2.1875C0.171875 2.60938 0.171875 3.3125 0.59375 3.78125L7.8125 11L0.59375 18.2656C0.171875 18.7344 0.171875 19.4375 0.59375 19.8594L1.67188 20.9375C2.09375 21.3594 2.79688 21.3594 3.26562 20.9375L12.3594 11.7969Z" fill={color} fillOpacity="0.8" />
      </svg>

    );
  };
  const ChevronBackward = ({ width = 22, height = 22, color = 'white' }: Props) => {
    return (
      <svg width={width} height={height} viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.59375 10.25C0.171875 10.6719 0.171875 11.375 0.59375 11.7969L9.6875 20.9375C10.1562 21.3594 10.8594 21.3594 11.2812 20.9375L12.3594 19.8594C12.7812 19.4375 12.7812 18.7344 12.3594 18.2656L5.14062 11L12.3594 3.78125C12.7812 3.3125 12.7812 2.60938 12.3594 2.1875L11.2812 1.10938C10.8594 0.6875 10.1562 0.6875 9.6875 1.10938L0.59375 10.25Z" fill={color} fillOpacity="0.8" />
      </svg>

    );
  };
  const LetterIcon = ({ width = 43, height = 43, color = 'white' }: Props) => {
    return (
      <svg width={width} height={height} viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="21.2121" cy="21.2121" r="21.2121" fill="#1C6162" />
        <path d="M31.4533 20.459C30.5099 21.1973 29.3205 22.0996 25.1369 25.1348C24.3165 25.75 22.799 27.1035 21.3634 27.1035C19.8869 27.1035 18.4103 25.75 17.549 25.1348C13.3654 22.0996 12.1759 21.1973 11.2326 20.459C11.0685 20.3359 10.8634 20.459 10.8634 20.6641V29.0312C10.8634 30.1387 11.7248 31 12.8322 31H29.8947C30.9611 31 31.8634 30.1387 31.8634 29.0312V20.6641C31.8634 20.459 31.6173 20.3359 31.4533 20.459ZM21.3634 25.75C22.3068 25.791 23.6603 24.5605 24.3576 24.0684C29.8126 20.1309 30.2228 19.7617 31.4533 18.7773C31.6994 18.6133 31.8634 18.3262 31.8634 17.998V17.2188C31.8634 16.1523 30.9611 15.25 29.8947 15.25H12.8322C11.7248 15.25 10.8634 16.1523 10.8634 17.2188V17.998C10.8634 18.3262 10.9865 18.6133 11.2326 18.7773C12.463 19.7617 12.8732 20.1309 18.3283 24.0684C19.0255 24.5605 20.379 25.791 21.3634 25.75Z" fill={color} />
      </svg>
    );
  };
  const LinkedInIcon = ({ width = 43, height = 43, color = 'white' }: Props) => {
    return (
      <svg width={width} height={height} viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="21.2121" cy="21.2121" r="21.2121" fill="#33BC92" />
        <path d="M16.2775 31V18.7363H12.463V31H16.2775ZM14.3498 17.0957C15.5802 17.0957 16.5646 16.0703 16.5646 14.8398C16.5646 13.6504 15.5802 12.666 14.3498 12.666C13.1603 12.666 12.1759 13.6504 12.1759 14.8398C12.1759 16.0703 13.1603 17.0957 14.3498 17.0957ZM30.5099 31H30.5509V24.2734C30.5509 20.9922 29.8126 18.4492 25.9572 18.4492C24.1115 18.4492 22.881 19.4746 22.3478 20.418H22.3068V18.7363H18.6564V31H22.4708V24.9297C22.4708 23.3301 22.758 21.8125 24.7267 21.8125C26.6955 21.8125 26.7365 23.6172 26.7365 25.0527V31H30.5099Z" fill={color} />
      </svg>
    );
  };
  const RigthArrow = ({ width = 12, height = 19, color = 'white' }: Props) => {
    return (
      <svg width={width} height={height} viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.127 9.82227C11.4961 9.45312 11.4961 8.83789 11.127 8.46875L3.16992 0.470703C2.75977 0.101562 2.14453 0.101562 1.77539 0.470703L0.832031 1.41406C0.462891 1.7832 0.462891 2.39844 0.832031 2.80859L7.14844 9.125L0.832031 15.4824C0.462891 15.8926 0.462891 16.5078 0.832031 16.877L1.77539 17.8203C2.14453 18.1895 2.75977 18.1895 3.16992 17.8203L11.127 9.82227Z" fill={color} />
      </svg>
    );
  };
  const PhoneCallIcon = ({ width = 60, height = 60, color = 'white' }: Props) => {
    return (
      <svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40" cy="40" r="40" fill="#33BC92" />
        <path d="M56.9453 47.9531L49.0703 44.5781C48.8594 44.5078 48.6484 44.4375 48.3672 44.4375C47.875 44.4375 47.3828 44.7188 47.1016 45.0703L43.5859 49.3594C38.1016 46.7578 33.7422 42.3984 31.1406 36.9141L35.4297 33.3984C35.7812 33.1172 36.0625 32.625 36.0625 32.0625C36.0625 31.8516 35.9922 31.6406 35.9219 31.4297L32.5469 23.5547C32.2656 22.9219 31.6328 22.5 30.9297 22.5C30.8594 22.5 30.7188 22.5703 30.5781 22.5703L23.2656 24.2578C22.4922 24.4688 22 25.1016 22 25.875C22 43.9453 36.5547 58.5 54.625 58.5C55.3984 58.5 56.0312 58.0078 56.2422 57.2344L57.9297 49.9219C57.9297 49.7812 57.9297 49.6406 57.9297 49.5703C57.9297 48.8672 57.5078 48.2344 56.9453 47.9531Z" fill={color} />
      </svg>
    );
  };
  const AssessmentIcon = ({ width = 60, height = 60, color = 'white' }: Props) => {
    return (
      <svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40" cy="40" r="40" fill="#33BC92" />
        <path d="M31.7734 25.0312C31.6328 24.8906 31.4219 24.75 31.2109 24.75C30.9297 24.75 30.7188 24.8906 30.5781 25.0312L26.0781 29.4609L24.5312 27.9141C24.3906 27.7734 24.1797 27.7031 23.8984 27.7031C23.6875 27.7031 23.4766 27.7734 23.3359 27.9141L22.2109 29.0391C22.0703 29.1797 21.9297 29.3906 21.9297 29.6016C21.9297 29.8828 22.0703 30.0938 22.2109 30.2344L25.5859 33.5391C25.7266 33.6797 25.9375 33.8203 26.1484 33.8203C26.4297 33.8203 26.6406 33.6797 26.7812 33.5391L27.9062 32.4844L32.9688 27.3516C33.1094 27.2109 33.25 27 33.25 26.7891C33.25 26.5781 33.1094 26.3672 32.9688 26.1562L31.7734 25.0312ZM31.7734 36.2109C31.6328 36.0703 31.4219 36 31.2109 36C30.9297 36 30.7188 36.0703 30.5781 36.2109L26.0781 40.7109L24.5312 39.1641C24.3906 39.0234 24.1797 38.8828 23.8984 38.8828C23.6875 38.8828 23.4766 39.0234 23.3359 39.1641L22.2109 40.2188C22.0703 40.4297 21.9297 40.6406 21.9297 40.8516C21.9297 41.0625 22.0703 41.2734 22.2109 41.4141L25.5859 44.7891C25.7266 44.9297 25.9375 45.0703 26.1484 45.0703C26.4297 45.0703 26.6406 44.9297 26.7812 44.7891L27.9062 43.6641L32.9688 38.6016C33.1094 38.4609 33.25 38.25 33.25 38.0391C33.25 37.7578 33.1094 37.5469 32.9688 37.4062L31.7734 36.2109ZM26.5 48.375C24.6016 48.375 23.0547 49.9219 23.0547 51.75C23.0547 53.6484 24.6016 55.125 26.5 55.125C28.3281 55.125 29.875 53.6484 29.875 51.75C29.875 49.9219 28.3281 48.375 26.5 48.375ZM56.875 49.5H36.625C35.9922 49.5 35.5 50.0625 35.5 50.625V52.875C35.5 53.5078 35.9922 54 36.625 54H56.875C57.4375 54 58 53.5078 58 52.875V50.625C58 50.0625 57.4375 49.5 56.875 49.5ZM56.875 27H36.625C35.9922 27 35.5 27.5625 35.5 28.125V30.375C35.5 31.0078 35.9922 31.5 36.625 31.5H56.875C57.4375 31.5 58 31.0078 58 30.375V28.125C58 27.5625 57.4375 27 56.875 27ZM56.875 38.25H36.625C35.9922 38.25 35.5 38.8125 35.5 39.375V41.625C35.5 42.2578 35.9922 42.75 36.625 42.75H56.875C57.4375 42.75 58 42.2578 58 41.625V39.375C58 38.8125 57.4375 38.25 56.875 38.25Z" fill={color} />
      </svg>
    );
  };
  const InterviewIcon = ({ width = 60, height = 60, color = 'white' }: Props) => {
    return (
      <svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40" cy="40" r="40" fill="#33BC92" />
        <path d="M43.0234 56.8828C43.5859 54.8438 44.5 49.8516 44.5 47.5312C44.5 45.0703 42.25 44.4375 40 44.4375C37.6797 44.4375 35.5 45.0703 35.5 47.5312C35.5 49.8516 36.3438 54.9141 36.9062 56.8828C37.2578 58.2188 38.6641 58.5 40 58.5C41.2656 58.5 42.6719 58.2188 43.0234 56.8828ZM35.2188 42.8203C33.9531 41.5547 33.1094 39.7266 33.25 37.6875C33.3906 34.2422 36.2031 31.3594 39.7188 31.2891C43.5156 31.0781 46.75 34.1719 46.75 37.9688C46.75 39.8672 45.9062 41.625 44.7109 42.8203C44.5 43.0312 44.5 43.3125 44.7109 43.4531C45.4141 43.9453 45.9062 44.5781 46.1875 45.2812C46.3281 45.4922 46.6797 45.5625 46.8906 45.3516C48.9297 43.4531 50.125 40.7812 50.0547 37.7578C49.9844 32.4141 45.625 28.0547 40.2812 27.9141C34.5156 27.7031 29.875 32.3438 29.875 37.9688C29.875 40.9219 31.0703 43.5234 33.0391 45.3516C33.25 45.5625 33.6016 45.4922 33.7422 45.2812C34.0234 44.5781 34.5156 43.9453 35.2188 43.4531C35.4297 43.3125 35.4297 43.0312 35.2188 42.8203ZM40 22.5C31.2812 22.5 24.25 29.6016 24.25 38.25C24.25 44.5781 27.9062 49.9219 33.0391 52.4531C33.3906 52.5938 33.7422 52.3125 33.6719 52.0312C33.4609 50.9062 33.3203 49.8516 33.25 48.8672C33.25 48.7266 33.1797 48.6562 33.1094 48.5859C29.7344 46.3359 27.5547 42.5391 27.625 38.1797C27.625 31.4297 33.1797 25.9453 39.9297 25.9453C46.75 25.875 52.375 31.4297 52.375 38.25C52.375 42.6094 50.0547 46.4766 46.6797 48.6562C46.6094 49.6406 46.4688 50.8359 46.2578 52.0312C46.1875 52.3125 46.5391 52.5938 46.8906 52.4531C52.0234 49.9219 55.75 44.6484 55.75 38.25C55.75 29.6016 48.6484 22.5 40 22.5ZM40 33.75C37.4688 33.75 35.5 35.7891 35.5 38.25C35.5 40.7812 37.4688 42.75 40 42.75C42.4609 42.75 44.5 40.7812 44.5 38.25C44.5 35.7891 42.4609 33.75 40 33.75Z" fill={color} />
      </svg>
    );
  };
  const HiredIcon = ({ width = 60, height = 60, color = 'white' }: Props) => {
    return (
      <svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40" cy="40" r="40" fill="#33BC92" />
        <path d="M33.75 40.5C38.6719 40.5 42.75 36.4922 42.75 31.5C42.75 26.5781 38.6719 22.5 33.75 22.5C28.7578 22.5 24.75 26.5781 24.75 31.5C24.75 36.4922 28.7578 40.5 33.75 40.5ZM40.0078 42.75H38.8125C37.2656 43.5234 35.5781 43.875 33.75 43.875C31.9219 43.875 30.1641 43.5234 28.6172 42.75H27.4219C22.2188 42.75 18 47.0391 18 52.2422V55.125C18 57.0234 19.4766 58.5 21.375 58.5H46.125C47.9531 58.5 49.5 57.0234 49.5 55.125V52.2422C49.5 47.0391 45.2109 42.75 40.0078 42.75ZM62.7188 33.75L60.75 31.7812C60.4688 31.4297 59.9062 31.4297 59.625 31.7812L52.2422 39.0938L49.0078 35.8594C48.7266 35.5078 48.1641 35.5078 47.8125 35.8594L45.8438 37.8281C45.5625 38.1094 45.5625 38.6719 45.8438 39.0234L51.6094 44.7891C51.9609 45.1406 52.4531 45.1406 52.8047 44.7891L62.7188 34.9453C63.0703 34.5938 63.0703 34.1016 62.7188 33.75Z" fill={color} />
      </svg>
    );
  };

  const EarphonesIcon = ({ width = 51, height = 51, color = 'white' }: Props) => {
    return (
      <svg width={width} height={height} viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.25 20.5625C19.25 18.9023 17.7852 17.4375 16.125 17.4375H14.5625C11.0469 17.4375 8.3125 20.2695 8.3125 23.6875V28.375C8.3125 31.8906 11.0469 34.625 14.5625 34.625H16.125C17.7852 34.625 19.25 33.2578 19.25 31.5V20.5625ZM36.4375 34.625C39.8555 34.625 42.6875 31.8906 42.6875 28.375V23.6875C42.6875 20.2695 39.8555 17.4375 36.4375 17.4375H34.875C33.1172 17.4375 31.75 18.9023 31.75 20.5625V31.5C31.75 33.2578 33.1172 34.625 34.875 34.625H36.4375ZM25.5 0.25C11.5352 0.25 0.890625 11.8711 0.5 25.25V26.8125C0.5 27.6914 1.18359 28.375 2.0625 28.375H3.625C4.40625 28.375 5.1875 27.6914 5.1875 26.8125V25.25C5.1875 14.1172 14.2695 4.9375 25.5 4.9375C36.6328 4.9375 45.8125 14.1172 45.8125 25.25H45.7148C45.7148 25.543 45.8125 41.4609 45.8125 41.4609C45.8125 43.8047 43.957 45.5625 41.6133 45.5625H31.75C31.75 43.0234 29.6016 40.875 27.0625 40.875H23.9375C21.3008 40.875 19.25 43.0234 19.25 45.5625C19.25 48.1992 21.3008 50.25 23.9375 50.25H41.6133C46.4961 50.25 50.5 46.3438 50.5 41.4609V25.25C50.0117 11.8711 39.3672 0.25 25.5 0.25Z" fill={color} />
      </svg>
    );
  };

  return {
    CloseIcon,
    HamburgerIcon,
    ChevronDownIcon,
    PeoplesIcon,
    CoffeeIcon,
    PizzaIcon,
    ChevronForward,
    ChevronBackward,
    LetterIcon,
    LinkedInIcon,
    RigthArrow,
    PhoneCallIcon,
    AssessmentIcon,
    InterviewIcon,
    HiredIcon,
    EarphonesIcon,
  };
};

export default useIcon;
