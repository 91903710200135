import React from 'react';
import classnames from 'classnames';
import Label from './label';

type Props = {
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  checked?: boolean | undefined;
  className?: string | undefined;
  label?: string | undefined;
  children?: React.ReactNode | undefined;
  color?: 'primary' | 'secondary' | undefined
};

const Checkbox = ({ onChange, checked, label, children, className, color }: Props) => {
  const classProps = classnames(
    className ? className : '',
    'flex items-center gap-2',
    color === 'primary' ? 'text-[#1C6162]' : '',
    color === 'secondary' ? 'text-[#33BC92]' : '',
  );
  return (
    <Label className={classProps}>
      <input checked={checked} onChange={onChange} type="checkbox" className="w-4 h-4 bg-transparent" />
      {label} {children}
    </Label>
  );
};

export default Checkbox;
