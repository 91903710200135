import Image from 'next/image'
import React from 'react'
import { Box, Button } from 'src/components/atoms'

type Props = {
  size?: 'sm' | 'lg' | undefined
  direction?: 'column' | 'row' | undefined
}

const AppButtons = ({ size = 'sm', direction = 'row' }: Props) => {
  return (
    <Box className={`flex items-center gap-2.5 ${direction === 'row' ? 'flex-row' : 'flex-col'}`}>
      <Button className='hover:scale-110 transition-all'>
        <Image alt='app_store' layout='fixed' src="/images/icons/header/1.png" width={size === 'sm' ? 134 : 201} height={size === 'sm' ? 40 : 60} />
      </Button>
      <Button className='hover:scale-110 transition-all'>
        <Image alt='play_store' layout='fixed' src="/images/icons/header/2.png" width={size === 'sm' ? 134 : 201} height={size === 'sm' ? 40 : 60} />
      </Button>
    </Box>
  )
}

export default AppButtons