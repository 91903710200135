import React from 'react';
import classnames from 'classnames';
import Spinner from './spinner';

type Props = {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string | undefined;
  variant?:
  | 'none'
  | 'primary'
  | 'secondary'
  | 'gray'
  | 'danger'
  | 'success'
  | 'warning'
  | 'outline-primary'
  | 'outline-secondary'
  | 'outline-danger'
  | 'outline-success'
  | 'transparent'
  | undefined;
  disabled?: boolean | undefined;
  type?: 'submit' | 'button' | undefined;
  loading?: boolean | undefined;
  hoverDisabled?: boolean | undefined
};

const Button = ({ children, onClick, className, disabled, variant = 'none', hoverDisabled, loading }: Props) => {
  const classProps: string = classnames(
    'text-sm leading-6 whitespace-nowrap',
    className ? className : '',
    hoverDisabled ? 'hover:!scale-100' : '',
    variant === 'none' ? '' : 'hover:!bg-[#add891] hover:!text-[#1C6162] hover:font-medium',
    variant === 'primary'
      ? `rounded-[0.625rem] px-10 py-3 bg-[#1C6162] ${disabled ? '' : ' hover:bg-[#1C6162] focus:bg-[#1C6162] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#1C6162] active:shadow-lg'
      } transition duration-150 ease-in-out text-white`
      : '',
    variant === 'secondary'
      ? `rounded-[0.625rem] px-10 py-3 bg-[#33BC92] ${disabled ? '' : 'hover:bg-[#33BC92] focus:bg-[#33BC92] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#33BC92] active:shadow-lg'
      } transition duration-150 ease-in-out text-white`
      : '',
    variant === 'gray'
      ? `rounded-[0.625rem] px-10 py-3 bg-[#69798D] ${disabled ? '' : 'hover:bg-[#69798D] focus:bg-[#69798D] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#69798D] active:shadow-lg'
      } transition duration-150 ease-in-out text-white`
      : '',
    disabled ? 'cursor-not-allowed' : '',
    'flex flex-row items-center justify-center',
  );

  return (
    <button disabled={disabled} className={classProps} onClick={onClick}>
      {children} {loading && <Spinner className="ml-2" />}
    </button>
  );
};

export default Button;
