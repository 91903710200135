import classnames from 'classnames';
import React from 'react';

type Props = {
  children?: React.ReactNode;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string | undefined;
  color?: 'primary' | 'secondary' | undefined
};

const Title = ({ children, tag = 'h1', className, color }: Props) => {
  const Component = tag;

  const classProps = classnames(
    className,
    'font-semibold',
    color === 'primary' ? 'text-[#1C6162]' : '',
    color === 'secondary' ? 'text-[#33BC92]' : '',
  )

  return (
    <Component
      className={classProps}
    >
      {children}
    </Component>
  );
};

export default Title;
