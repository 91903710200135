import React from 'react';
import classnames from 'classnames';

type Props = {
  children?: React.ReactNode;
  className?: string | undefined;
  color?: 'primary' | 'secondary' | undefined
};

const Strong = ({ children, className, color }: Props) => {
  const classProps = classnames(
    className ? className : '',
    'font-medium text-gray-600',
    color === 'primary' ? 'text-[#1C6162]' : '',
    color === 'secondary' ? 'text-[#33BC92]' : '',
  );

  return <strong className={classProps}>{children}</strong>;
};

export default Strong;
