export const blog = [
  {
    categoryId: 0,
    categoryName: 'TIPS & TRICKS',
    blogId: 1,
    title: 'We’ve Joined Intuit’s Climate Action marketplace',
    createdAt: 'May 03, 2022',
    owner: 'İlhan',
    imgUrl: '/images/home/10.jpg'
  },
  {
    categoryId: 0,
    categoryName: 'TIPS & TRICKS',
    blogId: 2,
    title: 'We’ve Joined Intuit’s Climate Action marketplace',
    createdAt: 'May 03, 2022',
    owner: 'İlhan',
    imgUrl: '/images/home/10.jpg'
  },
  {
    categoryId: 0,
    categoryName: 'TIPS & TRICKS',
    blogId: 3,
    title: 'We’ve Joined Intuit’s Climate Action marketplace',
    createdAt: 'May 03, 2022',
    owner: 'İlhan',
    imgUrl: '/images/home/10.jpg'
  },
  {
    categoryId: 1,
    categoryName: 'TIPS & TRICKS',
    blogId: 4,
    title: 'We’ve Joined Intuit’s Climate Action marketplace',
    createdAt: 'May 03, 2022',
    owner: 'İlhan',
    imgUrl: '/images/home/10.jpg'
  },
  {
    categoryId: 1,
    categoryName: 'TIPS & TRICKS',
    title: 'We’ve Joined Intuit’s Climate Action marketplace',
    createdAt: 'May 03, 2022',
    owner: 'İlhan',
    blogId: 5,
    imgUrl: '/images/home/10.jpg'
  },
  {
    categoryId: 1,
    categoryName: 'TIPS & TRICKS',
    blogId: 6,
    title: 'We’ve Joined Intuit’s Climate Action marketplace',
    createdAt: 'May 03, 2022',
    owner: 'İlhan',
    imgUrl: '/images/home/10.jpg'
  },
  {
    categoryId: 1,
    categoryName: 'TIPS & TRICKS',
    blogId: 7,
    title: 'We’ve Joined Intuit’s Climate Action marketplace',
    createdAt: 'May 03, 2022',
    owner: 'İlhan',
    imgUrl: '/images/home/10.jpg'
  },
  {
    categoryId: 1,
    blogId: 8,
    categoryName: 'TIPS & TRICKS',
    title: 'We’ve Joined Intuit’s Climate Action marketplace',
    createdAt: 'May 03, 2022',
    owner: 'İlhan',
    imgUrl: '/images/home/10.jpg'
  },
  {
    categoryId: 1,
    categoryName: 'TIPS & TRICKS',
    blogId: 9,
    title: 'We’ve Joined Intuit’s Climate Action marketplace',
    createdAt: 'May 03, 2022',
    owner: 'İlhan',
    imgUrl: '/images/home/10.jpg'
  },
  {
    categoryId: 2,
    categoryName: 'TIPS & TRICKS',
    blogId: 10,
    title: 'We’ve Joined Intuit’s Climate Action marketplace',
    createdAt: 'May 03, 2022',
    owner: 'İlhan',
    imgUrl: '/images/home/10.jpg'
  },
  {
    categoryId: 3,
    categoryName: 'TIPS & TRICKS',
    blogId: 11,
    title: 'We’ve Joined Intuit’s Climate Action marketplace',
    createdAt: 'May 03, 2022',
    owner: 'İlhan',
    imgUrl: '/images/home/10.jpg'
  },
  {
    categoryId: 4,
    categoryName: 'TIPS & TRICKS',
    blogId: 12,
    title: 'We’ve Joined Intuit’s Climate Action marketplace',
    createdAt: 'May 03, 2022',
    owner: 'İlhan',
    imgUrl: '/images/home/10.jpg'
  },
  {
    categoryId: 5,
    categoryName: 'TIPS & TRICKS',
    blogId: 13,
    title: 'We’ve Joined Intuit’s Climate Action marketplace',
    createdAt: 'May 03, 2022',
    owner: 'İlhan',
    imgUrl: '/images/home/10.jpg'
  },
  {
    categoryId: 6,
    categoryName: 'TIPS & TRICKS',
    blogId: 14,
    title: 'We’ve Joined Intuit’s Climate Action marketplace',
    createdAt: 'May 03, 2022',
    owner: 'İlhan',
    imgUrl: '/images/home/10.jpg'
  },
];


export const categoriesByBlog = [
  {
    categoryId: 0,
    categoryName: 'CATEGORY 1'
  },
  {
    categoryId: 1,
    categoryName: 'CATEGORY 2'
  },
  {
    categoryId: 2,
    categoryName: 'CATEGORY 3'
  },
  {
    categoryId: 3,
    categoryName: 'CATEGORY 4'
  },
  {
    categoryId: 4,
    categoryName: 'CATEGORY 5'
  },
  {
    categoryId: 5,
    categoryName: 'CATEGORY 6'
  },
]