import React from 'react';
import classnames from 'classnames';

type Props = {
  children: React.ReactNode;
  className?: string | undefined;
  color?: 'primary' | 'secondary' | undefined

};

const Label = ({ children, className, color }: Props) => {
  const classProps = classnames(
    className ? className : '', 'flex gap-2',
    color === 'primary' ? 'text-[#1C6162]' : '',
    color === 'secondary' ? 'text-[#33BC92]' : '',
  );

  return <label className={classProps}>{children}</label>;
};

export default Label;
