// React
// Helpers
import React, { useEffect } from 'react';
// Next Modules
import { DefaultSeo } from 'next-seo';
import { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
// Third Party Modules
import NProgress from 'nprogress';
import { scrollTo } from '@utils';
// Store
import { wrapper } from '@store';
// Styles
import 'tailwindcss/tailwind.css';
import '@styles/global.scss';
import { I18nextProvider, useTranslation } from 'react-i18next';
import '../i18n';
import { Footer, Header } from '@components';

// NProgress Config
NProgress.configure({ showSpinner: false });
// AppProps
type CustomAppProps = AppProps & { pathname?: string };

// App
const App = ({ Component, pageProps, pathname }: CustomAppProps): JSX.Element => {
  const router = useRouter()
  const { locale } = router;
  const { i18n } = useTranslation()

  useEffect(() => {
    i18n?.changeLanguage(locale);
  }, [locale, i18n]);


  useEffect(() => {
    // Next Content
    const nextContent = document.getElementById('__next');
    // Router Events
    Router.events.on('routeChangeStart', () => NProgress.start());
    Router.events.on('routeChangeComplete', () => {
      // NProgress
      NProgress.done();
      // Scroll To Top
      if (nextContent instanceof HTMLElement) scrollTo(nextContent, 0, 0);
    });

    Router.events.on('routeChangeError', () => NProgress.done());
    // Load Transitions
    setTimeout(() => (nextContent instanceof HTMLElement ? nextContent.classList.add('loaded') : null), 500);
  }, []);
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
        {/* Application Settings */}
        <meta name="application-name" content={process.env.NEXT_PUBLIC_SITENAME} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content={process.env.NEXT_PUBLIC_SITENAME} />
        <meta name="description" content={process.env.NEXT_PUBLIC_DESCRIPTION} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-TileColor" content="#fffff" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#1d2338" />
        {/* Apple Icon */}
        <link rel="apple-touch-icon" sizes="180x180" href="/images/app-icons/180x180.png" />
        <link rel="apple-touch-icon" sizes="167x167" href="/images/app-icons/167x167.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/images/app-icons/152x152.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/images/app-icons/120x120.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/images/app-icons/76x76.png" />
        {/* Apple Splash */}
        <link rel="apple-touch-startup-image" sizes="2048x2732" href="/images/app-splashes/2048x2732.png" />
        <link rel="apple-touch-startup-image" sizes="1668x2224" href="/images/app-splashes/1668x2224.png" />
        <link rel="apple-touch-startup-image" sizes="1536x2048" href="/images/app-splashes/1536x2048.png" />
        <link rel="apple-touch-startup-image" sizes="1125x2436" href="/images/app-splashes/1125x2436.png" />
        <link rel="apple-touch-startup-image" sizes="1242x2208" href="/images/app-splashes/1242x2208.png" />
        <link rel="apple-touch-startup-image" sizes="750x1334" href="/images/app-splashes/750x1334.png" />
        <link rel="apple-touch-startup-image" sizes="640x1136" href="/images/app-splashes/640x1136.png" />
        {/* Favicon && Manifest */}
        <link rel="icon" sizes="32x32" href="/images/app-icons/32x32-circle.png" />
        <link rel="icon" sizes="16x16" href="/images/app-icons/16x16-circle.png" />
        <link rel="shortcut icon" href="/images/app-icons/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        {/* Preconnect */}
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://cdnjs.cloudflare.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="preconnect" href="https://maps.googleapis.com" />
        {/* Global Css */}
        <style type="text/css">{`#__next:not(.loaded) *{transition:none!important}#__next:not(.loaded) ::before,#__next:not(.loaded) ::after{visibility:hidden!important}`}</style>
      </Head>
      <DefaultSeo
        title={process.env.NEXT_PUBLIC_TITLE}
        description={process.env.NEXT_PUBLIC_DESCRIPTION}
        canonical={`${process.env.NEXT_PUBLIC_SEO_HOST}${pathname}`}
        openGraph={{
          type: 'website',
          url: `${process.env.NEXT_PUBLIC_SEO_HOST}${pathname}`,
          site_name: process.env.NEXT_PUBLIC_SITENAME,
          title: process.env.NEXT_PUBLIC_TITLE,
          description: process.env.NEXT_PUBLIC_DESCRIPTION,
          images: [
            {
              url: `${process.env.NEXT_PUBLIC_HOST}/images/common/og-default.png`,
            },
          ],
        }}
      />
      <I18nextProvider i18n={i18n}>
        {/* header */}
        <Header />
        <main>
          <Component {...pageProps} />
        </main>
        {/* footer */}
        <Footer />
      </I18nextProvider>
    </>
  );
};
// InitialProps
App.getInitialProps = (context: AppContext) => ({
  pathname: context.ctx.req?.url,
});
// Export
export default wrapper.withRedux(App);
