export const languages = [
  {
    id: 0,
    lang: 'EN',
    locale: 'en',
  }, {
    id: 1,
    lang: 'TR',
    locale: 'tr',
  }
]