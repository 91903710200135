import { useRouter } from 'next/router'
import React from 'react'
import { AppLink, List, ListItem, Nav } from 'src/components/atoms'
import { navsTR, navsEN } from 'src/mocks'


const Navs = () => {
  const router = useRouter()
  const navs = router.locale === 'tr' ? navsTR : navsEN

  return (
    <Nav>
      <List className='flex items-center gap-2'>
        {navs.map((i, index: number) => {
          return (
            <ListItem key={index}>
              <AppLink href={i.slug} className={`${index === 1 ? 'py-3 px-4 bg-[#1C6162] !text-white rounded-2xl hover:text-[#1C6162]' : ''} uppercase hover:bg-[#add891] hover:!text-[#1C6162] text-[#1C6162] rounded-2xl transition-all py-3 px-4 font-medium text-base`}>
                {i.label}
              </AppLink>
            </ListItem>
          )
        })}
      </List>
    </Nav>
  )
}

export default Navs