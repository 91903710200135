import React, { useState } from 'react';
import classnames from 'classnames';
import { size } from 'lodash';
import { Input, Label, Span } from '.';

type Props = {
  label: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  type?: 'text' | 'password';
  variant?: 'primary' | 'secondary' | undefined
};

const FloatInput = ({ label, type = 'text', variant = 'primary', value = '', onChange }: Props) => {
  const [tempType, setTempType] = useState(type);
  const classProps = classnames(
    'absolute rounded transition-all text-gray-400 duration-150 ease-in left-4',
    size(value) > 0 ? 'top-[2px] text-[10px] bg-opacity-100' : 'top-1/2 translate-y-[-50%] text-sm bg-opacity-0',
  );

  return (
    <Label className="relative flex-col">
      <Span className={classProps}>{label}</Span>
      <Input variant={variant} type={tempType} value={value} className={type === 'password' ? 'py-3 pl-2 pr-16' : 'p-3'} onChange={onChange} />
      {type === 'password' && (
        <Span className="absolute top-1/2 translate-y-[-52%] right-4 text-[#BECBDC] font-medium" onClick={() => setTempType(tempType === 'text' ? 'password' : 'text')}>
          {tempType === 'text' ? 'Gizle' : 'Göster'}
        </Span>
      )}
    </Label>
  );
};

export default FloatInput;
