import React from "react";

type Props = {
  children?: React.ReactNode;
  onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined;
  className?: string | undefined;
};

const Form = ({ children, onSubmit, className }: Props) => {
  return (
    <form className={className} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default Form;
