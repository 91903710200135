import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppLink, Button, List, ListItem, Span } from 'src/components/atoms'
import { useIcon } from 'src/components/icons'
import useOnClickOutside from 'src/hooks/use-on-click-outside'
import { languages } from 'src/mocks/languages'


const SwitchLanguage = () => {
  const router = useRouter()
  const [lang, setLang] = useState<string | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const { i18n } = useTranslation()
  const { ChevronDownIcon } = useIcon()
  const wrapperRef = useRef(null)

  useEffect(() => {
    const activeLang = languages.find((i) => i.locale === router.locale)
    activeLang && setLang(activeLang.lang)
  }, [router.locale])

  useOnClickOutside(wrapperRef, () => setOpen(false))

  const handleClick = (lang: string) => {
    i18n.changeLanguage(lang)
    setOpen(false)
  }

  return (
    <div ref={wrapperRef} className='relative shadow-sm border border-gray-100 hover:bg-[#add891] hover:text-[#1C6162] rounded-xl h-9 w-20 flex items-center justify-center'>
      <Button onClick={() => setOpen(!open)} className='flex cursor-pointer gap-4 items-center justify-between'>
        <Span className='text-[#1C6162]'>{lang}</Span>
        <Span className={`${open ? '-rotate-180' : ''} transition-all`}>
          <ChevronDownIcon color='#1C6162' width={22} height={22} />
        </Span>
      </Button>
      {
        open &&
        <List className="absolute py-2 top-full left-0 right-0 mt-2 rounded-xl bg-white hover:bg-white z-10 border border-gray-100 shadow-sm">
          {
            languages.map((i, index: number) => {
              return (
                <ListItem className={`flex justify-center ${i.lang === lang ? 'hidden' : ''}`} key={index}>
                  <AppLink href={router.asPath} locale={i.locale} onClick={() => handleClick(i.lang)} className='text-[#1C6162] py-1 font-medium'>{i.lang}
                  </AppLink>
                </ListItem>
              )
            })
          }
        </List>
      }
    </div>

  )
}

export default SwitchLanguage