// Next Modules
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
// Third Party Modules
import assign from 'lodash/assign';
// Store
import { AppStore } from '@store';

// WithRedux
export const withRedux =
  (pageProps: GetServerSideProps, { store }: { store: AppStore }) =>
  async (ctx: GetServerSidePropsContext) => {
    // Store
    // const { constants, menu, locations } = selectApp(store.getState());
    // Get Constants
    // const getConstants = async () => {
    //     try {
    //         const constantsData = (await axios.get(`${process.env.NEXT_PUBLIC_HOST}/api/constants`)).data;
    //         // Set Constants
    //         store.dispatch(appSlice.actions.setConstants(constantsData));
    //         // Return
    //         return constantsData;
    //     } catch (error) {
    //         return error;
    //     }
    // };
    // // Get Menu
    // const getMenu = async () => {
    //     try {
    //         const menuData = (await axios.get(`${process.env.NEXT_PUBLIC_HOST}/api/menu`)).data;
    //         // Set Menu
    //         store.dispatch(appSlice.actions.setMenu(menuData));
    //         // Return
    //         return menuData;
    //     } catch (error) {
    //         return error;
    //     }
    // };
    // // Get Locations
    // const getLocations = async () => {
    //     try {
    //         const locationsData = (await axios.get(`${process.env.NEXT_PUBLIC_HOST}/api/locations`)).data;
    //         // Set Locations
    //         store.dispatch(appSlice.actions.setLocations(locationsData));
    //         // Return
    //         return locationsData;
    //     } catch (error) {
    //         return error;
    //     }
    // };
    // Promise All
    const [
      // , , ,
      promiseProps,
    ] = await Promise.all([
      // isEmpty(constants) ? getConstants() : null,
      // isEmpty(menu) ? getMenu() : null,
      // isEmpty(locations) ? getLocations() : null,
      pageProps ? pageProps(ctx) : null,
    ]);
    // Return
    if (promiseProps) return assign({ ...promiseProps });
    else return assign({ props: {} });
  };
