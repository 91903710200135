import React from 'react';
import classnames from 'classnames';

type Props = {
  children?: React.ReactNode;
  className?: string | undefined;
  onClick?: React.MouseEventHandler<HTMLSpanElement> | undefined;
  color?: 'primary' | 'secondary' | undefined

};

const Span = ({ children, className, onClick, color }: Props) => {
  const classProps = classnames(className ? className : '',
    color === 'primary' ? 'text-[#1C6162]' : '',
    color === 'secondary' ? 'text-[#33BC92]' : '',
  );

  return (
    <span onClick={onClick} className={classProps}>
      {children}
    </span>
  );
};

export default Span;
