export const homeCarousel = [
  {
    imgUrl: "/images/home/8.png",
    title: "SAVE FOOD",
    subTitle: "HELP THE PLANET",
    description: "We dream of a planet with no food waste, and every day we’re working on making that a reality. "
  },
  {
    imgUrl: "/images/home/2.png",
    title: "LOREM IPSUM",
    subTitle: "LOREM IPSUM LOREM",
    description: "We dream of a planet with no food waste, and every day we’re working on making that a reality. "
  },
  {
    imgUrl: "/images/home/9.jpg",
    title: undefined,
    subTitle: undefined,
    description: undefined,
  }
];

export type homeCarouselModel = {
  imgUrl: string,
  title?: string | undefined,
  subTitle?: string | undefined,
  description?: string | undefined
}[]