export const navsEN = [
  {
    id: 1,
    label: 'HOME',
    slug: '/',
  },
  {
    id: 2,
    label: 'REGISTER YOUR BUSINESS',
    slug: '/business',
  },
  {
    id: 3,
    label: 'MOVEMENT',
    slug: '/movement',
  },
  {
    id: 4,
    label: 'PRESS',
    slug: '/press',
  },
  {
    id: 5,
    label: 'TEAM',
    slug: '/team',
  },
  {
    id: 6,
    label: 'BLOG',
    slug: '/blog',
  },
  {
    id: 7,
    label: 'CAREERS',
    slug: '/careers',
  },
];

export const navsTR = [
  {
    id: 1,
    label: 'Anasayfa',
    slug: '/',
  },
  {
    id: 2,
    label: 'FİRMA',
    slug: '/business',
  },
  {
    id: 3,
    label: 'Hareket',
    slug: '/movement',
  },
  {
    id: 4,
    label: 'Baskı',
    slug: '/press',
  },
  {
    id: 5,
    label: 'TAKIM',
    slug: '/team',
  },
  {
    id: 6,
    label: 'BLOG',
    slug: '/blog',
  },
  {
    id: 7,
    label: 'KARİYER',
    slug: '/careers',
  },
];
