import React from 'react';
import classnames from 'classnames';

type Props = {
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  value?: string | number | readonly string[] | undefined;
  className?: string | undefined;
  variant?: 'primary' | 'secondary' | undefined
  type?: React.HTMLInputTypeAttribute | undefined;
};

const Input = ({ onChange, variant = 'primary', value, type = 'text', className }: Props) => {

  const classProps = classnames(className ? className : '',
    'rounded py-3 px-4 outline-0 border border-gray-300 text-sm leading-6 focus:ring-1 transition-all duration-150 ease-in',
    variant === 'primary' ? 'bg-white border border-[#BECBDC] rounded-[0.625rem] text-[#1C6162]' : '',
    variant === 'secondary' ? 'bg-white border border-[#BECBDC] rounded-[0.625rem] text-[#1C6162]' : '',
  );
  return <input className={classProps} value={value} onChange={onChange} type={type} />;
};

export default Input;
