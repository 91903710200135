import classnames from 'classnames';
import { size } from 'lodash';
import React from 'react';
import Box from './box';
import Span from './span';

type Props = {
  value: string | number | undefined;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  className?: string | undefined;
  label?: string | undefined;
  resize?: boolean | undefined;
  rows?: number | undefined;
  variant?: 'primary' | 'secondary' | undefined
};

const TextArea = ({ value, label, resize = false, rows = 6, variant = "primary", className, onChange }: Props) => {

  const classProps = classnames(
    'absolute rounded transition-all text-gray-400 duration-150 ease-in left-4',
    size(String(value)) > 0 ? 'top-[2px] text-[10px] bg-opacity-100' : 'top-4 text-sm bg-opacity-0',
  );

  const textAreaClass = classnames(
    "",
    className ? className : '',
    resize ? '' : 'resize-none',
    variant === 'primary' ? 'bg-white border  border-[#BECBDC] rounded-[0.625rem] text-[#BECBDC]' : '',
    'p-4 text-sm'
  )

  return (
    <Box className="relative flex flex-col">
      <Span className={classProps}>{label}</Span>
      <textarea value={value ? value : ''} defaultValue={''} onChange={onChange} className={textAreaClass} rows={rows} />
    </Box>
  );
};

export default TextArea;
