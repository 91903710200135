import Image from 'next/image'
import React, { useState } from 'react'
import { AppLink, Box, Button, Container, FloatInput, List, ListItem, Paragraph, Span, Title } from 'src/components/atoms'
import { AppButtons } from '../header'


const Footer = () => {
  const [mail, setMail] = useState('')
  return (
    <footer className='bg-[#1C6162] py-14 text-white'>
      <Container>
        <Paragraph className="text-base leading-6 text-center">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </Paragraph>
        <Title tag='h4' className='text-3xl text-center mt-10'>Subscribe Our Newsletter Now</Title>
        <Box className='grid grid-cols-4 max-w-3xl gap-5 mx-auto mt-8 mb-5'>
          <Box className='col-span-3'>
            <FloatInput value={mail} onChange={(e) => setMail(e.target.value)} label='Enter your email' />
          </Box>
          <Box className='col-span-1'>
            <Button variant='secondary' className='font-medium w-full'>Subscribe Now</Button>
          </Box>
        </Box>
        <Paragraph className="mx-auto max-w-2xl text-base leading-6 text-center">By clicking on the Subscribe button, you accept <br /> yemekoni&apos;s Privacy Policy,
          Terms and Conditions and Protection of Personal Data.
        </Paragraph>
        <hr className='my-10' />
        <Box className='grid grid-cols-5 gap-10'>
          <Box>
            <Title tag='h6' className='text-white text-xl'>SITEMAP</Title>
            <List className='text-white mt-5 flex flex-col gap-3 text-base'>
              <ListItem>
                <AppLink href="/" className='hover:underline'>
                  Home
                </AppLink>
              </ListItem>
              <ListItem>
                <AppLink href="/about-us" className='hover:underline'>
                  About Us
                </AppLink>
              </ListItem>
              <ListItem>
                <AppLink href="/business" className='hover:underline'>
                  Business
                </AppLink>
              </ListItem>
              <ListItem>
                <AppLink href="/movement" className='hover:underline'>
                  Movement
                </AppLink>
              </ListItem>
              <ListItem>
                <AppLink href="/blog" className='hover:underline'>
                  Blog
                </AppLink>
              </ListItem>
            </List>
          </Box>
          <Box>
            <Title tag='h6' className='text-white text-xl'>LINKS</Title>
            <List className='text-white mt-5 flex flex-col gap-3 text-base'>
              <ListItem>
                <AppLink href="/blog" className='hover:underline'>Support</AppLink>
              </ListItem>
              <ListItem>
                <AppLink href="/blog" className='hover:underline'>My Store</AppLink>
              </ListItem>
              <ListItem>
                <AppLink href="/blog" className='hover:underline'>Press</AppLink>
              </ListItem>
              <ListItem>
                <AppLink href="/blog" className='hover:underline'>Careers</AppLink>
              </ListItem>
              <ListItem>
                <AppLink href="/blog" className='hover:underline'>Contact</AppLink>
              </ListItem>
            </List>
          </Box>
          <Box>
            <Title tag='h6' className='text-white text-xl'>LEGAL</Title>
            <List className='text-white mt-5 flex flex-col gap-3 text-base'>
              <ListItem>
                <AppLink href="/blog" className='hover:underline'>Privacy Policy</AppLink>
              </ListItem>
              <ListItem>
                <AppLink href="/blog" className='hover:underline'>Terms & Conditions</AppLink>
              </ListItem>
              <ListItem>
                <AppLink href="/blog" className='hover:underline'>Cookie Policy</AppLink>
              </ListItem>
              <ListItem>
                <AppLink href="/blog" className='hover:underline'>Protection of Personal Data</AppLink>
              </ListItem>
            </List>
          </Box>
          <Box>
            <Title tag='h6' className='text-white text-xl'>OUR PARTNERS</Title>
            <List className='text-white mt-5 flex flex-col gap-3 text-base'>
              <ListItem>
                <AppLink href="/blog" className='hover:underline'>kosodu.com</AppLink>
              </ListItem>
              <ListItem>
                <AppLink href="/blog" className='hover:underline'>internethizi.com</AppLink>
              </ListItem>
              <ListItem>
                <AppLink href="/blog" className='hover:underline'>rilonu.com</AppLink>
              </ListItem>
            </List>
          </Box>
          <Box className='flex flex-col items-center'>
            <AppButtons direction='column' />
            <Span className='bg-white mt-7 pt-4 pb-3 px-4 rounded-xl inline-block'>
              <Image alt='mimpe_logo' src="/images/app/mimpe-logo.svg" layout='fixed' width={100} height={30} objectFit="contain" />
            </Span>
            <Span className="mt-2 inline-block">© 2023 ♥ ile yapıldı.</Span>
          </Box>
        </Box>

      </Container>
    </footer>
  )
}

export default Footer