import React from 'react';
import { Box, Logo } from 'src/components/atoms';
import AppButtons from './app-buttons';
import Navs from './navs';
import SwitchLanguage from './switch-language';


const Header = () => {
    return (
        <header className={`px-12 shadow-sm sticky scroll-shadow top-0 z-50 py-7 bg-white`}>
            <Box className='flex items-center justify-between'>
                <Logo width={250} height={44} />
                <Box className='flex items-center gap-14'>
                    <AppButtons />
                    <Navs />
                    <SwitchLanguage />
                </Box>
            </Box>
        </header>
    );
};

export default Header;
