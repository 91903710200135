import Image from 'next/image';
import React from 'react'
import { homeCarouselModel } from 'src/mocks';
import { EffectCoverflow, Navigation, Pagination, Autoplay, } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { Button, Paragraph, Span, Title } from '../atoms';
import { useIcon } from '../icons';

type Props = {
  data: homeCarouselModel
}

const CarouselSlider = ({ data }: Props) => {
  const swiper = useSwiper()
  const { ChevronForward, ChevronBackward } = useIcon()

  return (
    <div className='relative h-[calc(600px)]'>
      <Swiper
        className='carousel'
        effect='fade'
        slidesPerView={1}
        slidesPerGroup={1}
        speed={2000}
        loop
        autoplay={{ delay: 2000 }}
        modules={[EffectCoverflow, Navigation, Autoplay, Pagination]}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }}
        pagination={{
          clickable: true,
          bulletClass: `swiper-pagination-bullet pagination-bullet-primary`,

        }}
      >
        {data.map((item, index: number) => {
          return (
            <SwiperSlide key={index}>
              <div className='relative h-[calc(600px)] z-10 cursor-pointer'>
                <div className="bg-black bg-opacity-[5%] absolute top-0 left-0 bottom-0 right-0 z-50"></div>
                <Image layout='fill' priority src={item.imgUrl} alt={item?.title} objectFit='cover' />
                <div className="absolute left-64 top-1/2 translate-y-[-50%]">
                  {item.title && item.subTitle && <Title tag='h4' className='text-6xl leading-[70px] font-bold mb-5'>
                    {item.title && <Span className='text-[#33BC92]'>
                      {item?.title}
                    </Span>}
                    <br />
                    {item?.subTitle && <Span className='text-[#1C6162]'>
                      {item?.subTitle}
                    </Span>}
                  </Title>}
                  <Paragraph className='max-w-md font-medium text-lg leading-7 text-[#1C6162]'>
                    {item.description}
                  </Paragraph>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
        <Button onClick={() => swiper?.slidePrev()} className='swiper-button-prev !left-12 after:!hidden bg-[#33BC92] !w-14 !h-14 rounded-full'>
          <ChevronBackward />
        </Button>
        <Button onClick={() => swiper?.slideNext()} className='swiper-button-next !right-12 after:!hidden bg-[#33BC92] !w-14 !h-14 grid  rounded-full'>
          <ChevronForward />
        </Button>
      </Swiper>
    </div >
  )
}

export default CarouselSlider